import React from 'react'

import { interWbNavigate } from 'inter-webview-bridge'

import { BabiImg } from '@/assets/images'
import { CDN_STATIC } from '@/common/constants'

import { Skeleton } from '../Skeleton'
import { Text } from '../Text'
import * as S from './styles'

type BabiConfig = {
  title: string
  subtitle: string
}

export const DEFAULT_BABI_CONFIG = {
  title: 'Precisa de ajuda?',
  subtitle: 'Converse com a gente pelo chat. A Babi vai te ajudar.',
}

export const Babi = React.memo(() => {
  const [babiConfig, setBabiConfig] = React.useState<BabiConfig>()

  React.useEffect(() => {
    fetch(`${CDN_STATIC}/inter-ui/remote-config/babi.json`)
      .then((response) => response.json())
      .then((result) => setBabiConfig({ title: result.title, subtitle: result.subtitle }))
      .catch(() => setBabiConfig(DEFAULT_BABI_CONFIG))
  }, [])

  const onBabiClick = React.useCallback(() => {
    interWbNavigate.requetBabi()
  }, [])

  return babiConfig ? (
    <S.BabiContainer onClick={onBabiClick} data-testid="babi">
      <S.WrapperImg>
        <BabiImg />
      </S.WrapperImg>

      <S.WrapperText>
        <Text color="typography" colorWeight={500} variant="body-1" bold>
          {babiConfig.title}
        </Text>
        <Text color="typography" colorWeight={500} variant="body-2">
          {babiConfig.subtitle}
        </Text>
      </S.WrapperText>

      <S.WrapperIcon>
        <S.ChevronRight />
      </S.WrapperIcon>
    </S.BabiContainer>
  ) : (
    <S.LoadingContainer data-test-id="babi-skeleton">
      <S.WrapperSkeletonImage>
        <Skeleton width="40px" height="40px" variant="circle" />
      </S.WrapperSkeletonImage>

      <S.WrapperSkeletonText>
        <Skeleton width="50%" height="6px" style={{ marginBottom: 8 }} />
        <Skeleton height="6px" />
      </S.WrapperSkeletonText>

      <S.WrapperSkeletonIcon>
        <Skeleton width="20px" height="20px" variant="circle" />
      </S.WrapperSkeletonIcon>
    </S.LoadingContainer>
  )
})
