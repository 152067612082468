import React, { useRef, useEffect, useCallback, memo } from 'react'

import { DEFAULT_ACCORDION_TRANSITION_DELAY } from '@/common/constants'

import * as S from './styles'

type CommonProps = {
  expanded: boolean
  onClick: () => void
}

type DefaultAccordionProps = {
  labelComponent?: never
  title: string
}

type CustomAccordionProps = {
  labelComponent: React.ReactNode
  title?: never
  backgroundColor?: string
}

export type AccordionProps = (Omit<DefaultHTMLAttrs, 'onChange'> & CommonProps) &
  (DefaultAccordionProps | CustomAccordionProps)

export const Component = ({
  children,
  expanded = false,
  title,
  onClick,
  labelComponent,
  ...attrs
}: PropsWithRequiredChildren<AccordionProps>) => {
  const detailsRef = useRef<HTMLDivElement>(null)
  const timeout = useRef<Timeout>()

  const handleClick = useCallback(() => {
    timeout.current && clearTimeout(timeout.current)
    onClick()
  }, [onClick])

  const changeDetailsMaxHeight = useCallback(
    (detailsElement: HTMLDivElement, maxHeight: string, delay?: number) => {
      const { style } = detailsElement
      style.maxHeight = `${detailsElement.scrollHeight}px`
      return setTimeout(() => {
        style.maxHeight = maxHeight
      }, delay)
    },
    [],
  )

  useEffect(() => {
    const detailsElement = detailsRef.current as HTMLDivElement

    if (expanded) {
      timeout.current = changeDetailsMaxHeight(
        detailsElement,
        'inherit',
        DEFAULT_ACCORDION_TRANSITION_DELAY,
      )
    } else if (detailsElement.style.maxHeight !== '') {
      timeout.current = changeDetailsMaxHeight(detailsElement, '0px', 50)
    }
  }, [changeDetailsMaxHeight, expanded])

  return (
    <S.Container {...attrs}>
      {title && (
        <S.Summary onClick={handleClick} role="button">
          {title}
        </S.Summary>
      )}

      {labelComponent && (
        <S.CustomLabelContainer onClick={handleClick} role="button">
          {labelComponent}
        </S.CustomLabelContainer>
      )}

      <S.Details ref={detailsRef} role="region">
        <S.DetailPanel isCustom={!!labelComponent}>{children}</S.DetailPanel>
      </S.Details>
    </S.Container>
  )
}

export const Accordion = memo(Component)
