import styled, { css } from 'styled-components'

import Info from '@interco/icons/bidis/v2/action/stats/info'
import Error from '@interco/icons/bidis/v2/action/stats/error'
import Warning from '@interco/icons/bidis/v2/action/stats/warning'
import Success from '@interco/icons/bidis/v2/action/stats/success'

import { AlertType, AlertProps } from './types'

export type StyledAlertProps = Pick<AlertProps, 'customBackgroundColor'> & {
  bgColor: AlertType
}

const modifiers = {
  success: () => css`
    background-color: var(--alert-success-background-color);
  `,
  warning: () => css`
    background-color: var(--alert-alert-background-color);
  `,
  error: () => css`
    background-color: var(--alert-error-background-color);
  `,
  grayscale: () => css`
    background-color: var(--alert-grayscale-background-color);
  `,
}

export const Container = styled.div<StyledAlertProps>`
  ${({ bgColor, customBackgroundColor }) => css`
    border-radius: var(--radiiMd);
    position: relative;
    padding: 16px 12px;
    display: flex;

    ${customBackgroundColor
      ? css`
          background-color: ${customBackgroundColor};
        `
      : modifiers[bgColor]}
  `}
`

export const AlertContent = styled.div`
  padding: 3px 13px;
`
export const CloseButton = styled.button`
  background-color: transparent;
  border-radius: 50%;

  &:hover {
    opacity: 30%;
  }
`

export const SuccessIconColor = styled(Success)`
  & > path:first-child {
    fill: var(--alert-success-icon-color);
  }
`
export const WarningIconColor = styled(Warning)`
  & > path:first-child {
    fill: var(--alert-alert-icon-color);
  }
`
export const ErrorIconColor = styled(Error)`
  & > path:first-child {
    fill: var(--alert-error-icon-color);
  }
`
export const GrayscaleIconColor = styled(Info)`
  & > path:first-child {
    fill: var(--alert-grayscale-icon-color);
  }
`
export const IconContainer = styled.div`
  min-width: 14px;
  max-width: 14px;
`
