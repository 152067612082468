import styled, { css } from 'styled-components'

import ChevronMoreIcon from '@interco/icons/orangeds/XL/chevron-down'
import {
  inputStyle,
  StyledInputProps,
  IconRight as InputIconRight,
} from '@/components/Input/styles'

export const Select = styled.select`
  ${({ hasIconRight, hasIconLeft, disabled }: StyledInputProps) => css`
    border: none;
    appearance: none;
    cursor: pointer;

    ${inputStyle({ hasIconRight, hasIconLeft, disabled })};
    ${hasIconRight && 'padding-right: 40px;'};
  `}
`

export const IconRight = styled(InputIconRight)`
  position: absolute;
  pointer-events: none;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
`

export const ChevronMore = styled(ChevronMoreIcon).attrs({
  width: 24,
  height: 24,
})<{ disabled: boolean }>`
  path {
    stroke: ${({ disabled }) =>
      disabled ? 'var(--select-native-arrow-disabled-color)' : 'var(--select-native-arrow-color)'};
    stroke-width: 2.5;
  }
`
