import React from 'react'

import * as S from './styles'
import { useSnackBar } from './useSnackBar'
import { SnackBar } from './snackBar'

export const SnackBars = () => {
  const { snackBars, closeSnackBar } = useSnackBar()

  return (
    <S.SnackBars>
      {snackBars.map((snackBar) => (
        <SnackBar
          {...snackBar}
          closeSnackbar={() => closeSnackBar(snackBar.id)}
          key={snackBar.id}
        />
      ))}
    </S.SnackBars>
  )
}
