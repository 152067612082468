import React, { memo, useMemo } from 'react'

import Slider, { Settings } from 'react-slick'

import * as S from './styles'

export type CarouselProps = {
  dotsType: 'small' | 'medium' | 'big'
  verticalMode: boolean
  children: React.ReactNode
  className?: string
  containerStyles?: React.CSSProperties
} & Settings

export const Component = ({
  dots = true,
  dotsType = 'small',
  verticalMode = false,
  children,
  className,
  containerStyles,
  ...rest
}: CarouselProps) => {
  const settings = useMemo(
    () => ({
      dots,
      vertical: verticalMode,
      verticalSwiping: verticalMode,
      nextArrow: <S.ArrowRight data-testid="arrow-right" />,
      prevArrow: <S.ArrowLeft data-testid="arrow-left" />,
      ...rest,
    }),
    [dots, rest, verticalMode],
  )

  return (
    <S.Container
      data-testid="carousel"
      className={className}
      hasTypeDots={dotsType}
      style={containerStyles}
    >
      <Slider {...settings}>{children}</Slider>
    </S.Container>
  )
}

export const Carousel = memo(Component)
