import React, { useCallback, useState } from 'react'

import { uuid } from '@/utils/uuid'

import { SnackBarShowRequest, SnackBarSettings } from './types'
import { SnackBars } from './snackBars'
import { SnackBarsContext } from './context'

export const SnackBarProvider = ({ children }: PropsWithRequiredChildren) => {
  const [snackBars, setSnackBars] = useState<SnackBarSettings[]>([])

  const handleCloseSnackBar = useCallback((id: string) => {
    setSnackBars((prevState) => prevState.filter((snack) => snack.id !== id))
  }, [])

  const handleCloseAllSnackBars = useCallback(() => {
    setSnackBars([])
  }, [])

  const handleShowSnackBar = useCallback(
    (settings: SnackBarShowRequest | SnackBarShowRequest[]) => {
      if (Array.isArray(settings)) {
        const newSnackBars = settings.map((config) => ({
          ...config,
          time: config.time || 5_000,
          id: uuid(),
        }))

        setSnackBars((prevState) => [...prevState, ...newSnackBars])
        return
      }

      setSnackBars((prevState) => [
        ...prevState,
        {
          ...settings,
          id: uuid(),
          time: settings.time || 5_000,
        },
      ])
    },
    [],
  )

  return (
    <SnackBarsContext.Provider
      value={{
        closeSnackBar: handleCloseSnackBar,
        closeAllSnackBars: handleCloseAllSnackBars,
        showSnackBar: handleShowSnackBar,
        snackBars,
      }}
    >
      {children}
      <SnackBars />
    </SnackBarsContext.Provider>
  )
}
