import styled from 'styled-components'

import ChevronRightIcon from '@interco/icons/orangeds/XL/chevron-right'

export const BabiContainer = styled.div`
  display: flex;
  border: 1px solid var(--gray200);
  border-radius: 8px;
  width: 100%;
  height: 86px;
  padding: 0 12px;
`

export const WrapperImg = styled.div`
  position: relative;
  display: flex;
  align-items: flex-end;
  margin-bottom: 0px;
`

export const WrapperText = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 15px;
  justify-content: center;
  width: 65%;

  span {
    margin-bottom: 4px;
    font-size: 14px;
    line-height: 17px;

    &:last-child {
      font-size: 12px;
      color: #6a6c72;
      line-height: 15px;
      margin-bottom: 0;
    }
  }
`

export const WrapperIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  justify-self: flex-end;
`

export const ChevronRight = styled(ChevronRightIcon).attrs({
  width: 24,
  height: 24,
})`
  path {
    stroke: var(--highlight-color);
    stroke-width: 3;
  }
`

export const LoadingContainer = styled.div`
  align-items: center;
  display: flex;
  height: 86px;
  justify-content: center;
  padding: 0 16px;
  width: 100%;
`

export const WrapperSkeletonImage = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`

export const WrapperSkeletonText = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 15px;
  justify-content: center;
  width: 100%;
`

export const WrapperSkeletonIcon = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-left: 15px;
`
