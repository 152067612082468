import { createContext } from 'react'

import { SnackBarSettings, SnackBarShowRequest } from './types'

type SnackBarsContext = {
  closeSnackBar: (id: string) => void
  closeAllSnackBars: () => void
  showSnackBar: (settings: SnackBarShowRequest | SnackBarShowRequest[]) => void
  snackBars: SnackBarSettings[]
}

export const SnackBarsContext = createContext<SnackBarsContext>({} as SnackBarsContext)
