import styled, { css } from 'styled-components'

import { DEFAULT_ACCORDION_TRANSITION_DELAY } from '@/common/constants'

export const Summary = styled.div`
  padding: 20px 16px;
  cursor: pointer;
  color: var(--accordion-summary-color);
`

export const Details = styled.div`
  max-height: 0;
  overflow: hidden;
  transition: max-height ease-in-out ${DEFAULT_ACCORDION_TRANSITION_DELAY}ms;
  color: var(--accordion-details-color);
`

export const DetailPanel = styled.div<{ isCustom: boolean }>`
  ${({ isCustom }) => css`
    padding: 20px 16px;
    ${!isCustom && 'border-top: 1px solid var(--gray200);'}
  `}
`

export const Container = styled.div`
  background: var(--accordion-container-background-color);
  border-radius: var(--accordion-container-border-radius);
`

export const CustomLabelContainer = styled.div`
  cursor: pointer;
`
