import React, { memo } from 'react'

import * as S from './styles'

export type SkeletonProps = DefaultHTMLAttrs<HTMLDivElement> & {
  width?: string
  height?: string
  animation?: S.AnimationTypes
  variant?: S.VariantTypes
}

const Component = ({
  width = '100%',
  height = '100%',
  animation = 'wave',
  variant = 'text',
  ...attrs
}: SkeletonProps) => {
  const ToRender = variant === 'circle' && animation === 'wave' ? S.CircleContainer : S.Container

  return (
    <ToRender width={width} height={height} animation={animation} variant={variant} {...attrs} />
  )
}

export const Skeleton = memo(Component)
