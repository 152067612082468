import React, { useEffect, useMemo, useRef, useState, useCallback } from 'react'

import { Text } from '@/components/Text'

import * as S from './styles'
import { SnackBarSettings } from './types'

const REMOVE_DELAY = 200

export const SnackBar = ({
  type,
  title,
  content,
  time,
  onClick,
  onRemove,
  closeSnackbar,
  hasProgressBar,
  buttonLabel,
  onButtonClick,
  customIcon,
}: SnackBarSettings & { closeSnackbar: () => void }) => {
  const [show, setShow] = useState(false)
  const [hide, setHide] = useState(false)
  const [height, setHeight] = useState(0)
  const timeout = useRef<Timeout>()
  const isMounted = useRef(false)
  const snackBarRef = useRef<HTMLDivElement>(null)

  const getIcon = useMemo(
    () => ({
      NULL: <S.Insert data-testid="square-icon" />,
      SUCCESS: <S.CheckFill data-testid="success-icon" />,
      ERROR: <S.Exit data-testid="error-icon" />,
      ALERT: <S.Attention data-testid="alert-icon" />,
    }),
    [],
  )

  const close = useCallback(() => {
    if (!isMounted.current) return

    timeout.current && clearTimeout(timeout.current)
    setShow(false)
    setHide(true)

    timeout.current = setTimeout(() => {
      onRemove?.()
      closeSnackbar()
    }, REMOVE_DELAY)
  }, [closeSnackbar, onRemove])

  const showSnackBar = useCallback(() => {
    setHeight(snackBarRef.current?.offsetHeight || 0)

    setShow(true)
    timeout.current = setTimeout(() => {
      close()
    }, time)
  }, [close, time])

  useEffect(() => {
    isMounted.current = true
    if (time && !!closeSnackbar) {
      showSnackBar()
    }
    return () => {
      isMounted.current = false
    }
  }, [closeSnackbar, showSnackBar, time])

  return (
    <S.ContainerV2
      onClick={onClick}
      ref={snackBarRef}
      show={show}
      type={type}
      hide={hide}
      marginBottom={-height}
      role="dialog"
      data-testid="snackBar-container-v2"
      hasProgressBar={!!hasProgressBar}
      time={time || 5_000}
    >
      <S.TextWithIconContainer>
        {customIcon ? (
          <div className="snackbar-custom-icon">{customIcon}</div>
        ) : (
          <S.IconV2>{getIcon[type]}</S.IconV2>
        )}

        <S.Message>
          <Text variant="body-3" bold colorWeight={500}>
            {title}
          </Text>
          <S.TextS>
            <Text variant="caption-1" colorWeight={500} as="p">
              {content}
            </Text>
          </S.TextS>
        </S.Message>
      </S.TextWithIconContainer>
      <S.Clear>
        <S.Close
          data-testid="close-icon"
          onClick={(event: React.MouseEvent) => {
            event.stopPropagation()
            onButtonClick?.()
            close()
          }}
        >
          {buttonLabel || 'Fechar'}
        </S.Close>
      </S.Clear>
    </S.ContainerV2>
  )
}
