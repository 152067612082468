import styled, { css } from 'styled-components'

import AttentionIcon from '@interco/icons/orangeds/XL/attention'
import InsertIcon from '@interco/icons/bidis/v2/navigation/inserticon'
import ExitIcon from '@interco/icons/orangeds/LG/exit'
import CheckFillIcon from '@interco/icons/orangeds/XL/check-fill'

import { Flex } from '../Flex/styles'
import { SnackBarSettings } from './types'

type ContainerProps = {
  type: SnackBarSettings['type']
  show: boolean
  hide: boolean
  marginBottom: number
  hasProgressBar: boolean
  time: number
}

const displayModifiers = {
  show: 'opacity: 1;',
  hide: (marginBottom: number) => css`
    transform: translateX(430px);
    margin-bottom: ${marginBottom}px;
  `,
  hideTop: (marginBottom: number) => css`
    transform: translateY(-430px);
    margin-bottom: ${marginBottom}px;
  `,
}

const bgModifiers = {
  NULL: () => css`
    ${() => css`
      background-color: var(--snackbar-null-background-color);
    `}
  `,
  SUCCESS: () => css`
    ${() => css`
      background-color: var(--snackbar-success-background-color);
    `}
  `,
  ERROR: () => css`
    ${() => css`
      background-color: var(--snackbar-error-background-color);
    `}
  `,
  ALERT: () => css`
    ${() => css`
      background-color: var(--snackbar-alert-background-color);
    `}
  `,
}

const iconSize = { width: 24, height: 24 }
export const CheckFill = styled(CheckFillIcon).attrs(iconSize)`
  path {
    fill: var(--notification-success-background-color);
  }
`

export const Exit = styled(ExitIcon).attrs(iconSize)`
  path {
    stroke: var(--notification-error-background-color);
    stroke-width: 3;
  }
`

export const Insert = styled(InsertIcon).attrs(iconSize)`
  path {
    stroke: var(--notification-null-background-color);
    fill: none;
  }
`

export const Attention = styled(AttentionIcon).attrs(iconSize)`
  path {
    stroke: var(--neutral-theme);
  }

  circle {
    fill: var(--notification-alert-background-color);

    &:last-of-type {
      fill: var(--neutral-theme);
    }
  }
`

export const SnackBars = styled.div`
  position: fixed;
  top: 10px;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: var(--zIndexMedium);
`

const progressBarStyle = (time: number, hasProgressBar: boolean) => {
  if (!hasProgressBar) return ''
  return `
  &::after {
    content: '';
    position: absolute;
    position: absolute;
    background: var(--snackbar-progress-bar-background-color);
    width: 0px;
    height: 5px;
    border-radius: var(--snackbar-container-border-radius);
    animation: progressRevert ${time}ms linear;
    top: 0;
    right: 0;
  }

  @keyframes progressRevert{
    0%{width:100%}
    100%{width:0%}
  }
`
}

export const ContainerV2 = styled.div`
  ${({ type, show, hide, marginBottom, hasProgressBar, time }: ContainerProps) => css`
    position: relative;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: flex-start;
    padding: 16px 24px;
    box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.22);
    border-radius: var(--snackbar-container-border-radius);
    margin-bottom: 10px;
    max-width: 950px;
    width: 100%;
    transition: all ease-in-out 200ms;
    opacity: 0;
    flex-direction: row;
    @media (max-width: 500px) {
      flex-direction: column;
    }
    ${bgModifiers[type]()}
    ${progressBarStyle(time, hasProgressBar)}

    ${show && displayModifiers.show}
    ${hide && displayModifiers.hideTop(marginBottom)}
  `}
`

export const TextWithIconContainer = styled(Flex)`
  width: calc(100% - 50px);
  @media (max-width: 500px) {
    width: 100%;
  }
`
export const Icon = styled.div`
  background-color: var(--snackbar-icon-color);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const IconV2 = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Clear = styled.div`
  justify-self: flex-end;
  margin-left: auto;
`

export const Message = styled.div`
  display: flex;
  flex-flow: column wrap;
  margin-left: 8px;
  min-width: 250px;
  margin-right: 8px;
`

export const Close = styled.span`
  color: var(--orange500);
  cursor: pointer;
`

export const TextS = styled.div`
  margin-top: 5px;
`
