import React, { InputHTMLAttributes, memo } from 'react'

import * as S from './styles'

export type CheckboxProps = InputHTMLAttributes<HTMLInputElement> & {
  checked: boolean
  disabled?: boolean
  indeterminate?: boolean
  reversed?: boolean
  size?: number
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
} & ({ label?: never; id?: never } | { label?: string | React.ReactNode; id: string })

const Label: React.FC<Partial<CheckboxProps>> = ({ children, reversed }) => (
  <S.LabelText reversed={reversed}>{children}</S.LabelText>
)

const getCheckIcon = (indeterminate: boolean, checked: boolean) => {
  if (indeterminate) return <S.CheckboxIndeterminate />
  return checked ? <S.CheckboxOn /> : <S.CheckboxOff />
}

const Component = React.forwardRef(
  (
    {
      label = '',
      id,
      checked = false,
      defaultChecked,
      disabled = false,
      indeterminate = false,
      reversed = false,
      size = 32,
      onChange,
      ...attrs
    }: CheckboxProps,
    ref?: React.Ref<HTMLInputElement>,
  ) => (
    <S.LabelContainer disabled={disabled} htmlFor={id}>
      {reversed && label && <Label reversed={reversed}>{label}</Label>}

      <S.Input
        {...attrs}
        id={id}
        type="checkbox"
        ref={ref}
        defaultChecked={defaultChecked}
        disabled={disabled}
        onChange={onChange}
        checked={checked}
      />

      <S.StyledCheckbox size={size}>{getCheckIcon(indeterminate, checked)}</S.StyledCheckbox>

      {!reversed && label && <Label reversed={reversed}>{label}</Label>}
    </S.LabelContainer>
  ),
)

export const Checkbox = memo(Component)
