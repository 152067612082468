import React, { memo, PropsWithChildren } from 'react'

import Exit from '@interco/icons/orangeds/MD/exit'
import { Text } from '@/components/Text'

import * as S from './styles'
import { AlertProps, AlertType } from './types'

export type { AlertProps, AlertType } from './types'

const alertIconMapper: Record<AlertType, JSX.Element> = {
  success: <S.SuccessIconColor data-testid="icon" height={14} width={14} />,
  warning: <S.WarningIconColor height={14} width={14} />,
  error: <S.ErrorIconColor height={14} width={14} />,
  grayscale: <S.GrayscaleIconColor height={14} width={14} />,
}

const Icon = ({
  hideIcon,
  type,
  customIcon,
}: Pick<AlertProps, 'hideIcon' | 'type' | 'customIcon'>) => {
  if (hideIcon) return null
  if (customIcon) return customIcon
  return <S.IconContainer>{alertIconMapper[type as AlertType]}</S.IconContainer>
}

const Component = ({
  title,
  type,
  customBackgroundColor,
  customIcon,
  children,
  hideIcon = false,
  showCloseButton,
  onClose,
  ...props
}: PropsWithChildren<AlertProps>) => (
  <S.Container bgColor={type || 'success'} customBackgroundColor={customBackgroundColor} {...props}>
    <Icon hideIcon={hideIcon} type={type || 'success'} customIcon={customIcon} />

    <S.AlertContent>
      {title && (
        <Text
          color="typography"
          colorWeight={500}
          variant="caption-1"
          bold
          style={{ display: 'block' }}
        >
          {title}
        </Text>
      )}

      <Text color="typography" colorWeight={500} variant="caption-1" style={{ display: 'block' }}>
        {children}
      </Text>
    </S.AlertContent>
    {showCloseButton && (
      <S.CloseButton onClick={onClose}>
        <Exit color="var(--gray500)" />
      </S.CloseButton>
    )}
  </S.Container>
)

Component.defaultProps = {} as AlertProps

export const Alert = memo(Component)
