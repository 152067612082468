import React from 'react'

import * as S from './styles'
import * as Types from './types'

export * from './types'

const IconRender = ({ icon }: Types.IconRenderProps): JSX.Element => {
  if (icon) return icon as JSX.Element
  return <S.CleanIcon />
}

export const AppBar = React.memo(
  ({
    children,
    leftIcon = <S.ArrowLeft data-testid="app_bar-left_icon" />,
    rightIcon,
    hideLeftIcon = false,
    float = true,
    ...attrs
  }: Types.AppBarProps) => (
    <S.ContentPadding float={float}>
      <S.Container float={float} {...attrs} data-testid="app-bar-container">
        <S.IconContainer>{!hideLeftIcon && <IconRender icon={leftIcon} />}</S.IconContainer>

        <S.TitleContainer>
          {React.isValidElement(children) ? (
            children
          ) : (
            <S.Title variant="headline-h3" semiBold>
              {children}
            </S.Title>
          )}
        </S.TitleContainer>

        <S.IconContainer>
          <IconRender icon={rightIcon} />
        </S.IconContainer>
      </S.Container>
    </S.ContentPadding>
  ),
)
